/**
 * Content of the modal that opens when you request a deposit
 */
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useBankingAccount } from '@/services/useBankingAccount';
import { useExternalAccount } from '@/services/useExternalAccount';
import { useWithdrawFundsMutation } from '@/services/useWithdrawFunds';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import * as React from 'react';

type WithdrawFundsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function WithdrawFundsModal({ open, setOpen }: WithdrawFundsModalProps) {
  const [withdrawalAmount, setWithdrawalAmount] = React.useState(0);
  const [confirmWithdrawal, setConfirmWithdrawal] = React.useState(false);

  const {
    data: { available, current, pending } = {
      available: 0,
      current: 0,
      pending: 0,
    },
    isInitialLoading: isPendingAvailableAmount,
  } = useBankingAccount((data) => {
    const current = Math.max(0, data.balance);
    const pending = Math.max(0, data.pending);
    return {
      available: current - pending,
      current,
      pending,
    };
  });
  const { data: externalAccount, isLoading: isPendingExternalAccount } = useExternalAccount();

  // Default to the max available once it loads
  React.useEffect(() => {
    if (isPendingAvailableAmount) return;
    setWithdrawalAmount(available);
  }, [isPendingAvailableAmount, available]);

  const {
    mutate,
    isLoading: isSubmitting,
    error: rawSubmissionError,
  } = useWithdrawFundsMutation({
    onSuccess() {
      // Close dialog
      setOpen(false);
      // Reset confirmation
      setConfirmWithdrawal(false);
    },
  });

  const submissionError = rawSubmissionError?.response?.data;

  if (submissionError) {
    // eslint-disable-next-line no-console
    console.log({ submissionError });
  }

  const onSubmit = () => {
    mutate({ amountCents: withdrawalAmount });
  };

  const hasInsufficientFunds = withdrawalAmount > available;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-3xl space-y-2">
        <DialogHeader>
          <DialogTitle className="text-xl tracking-normal">Withdraw Funds</DialogTitle>
          <DialogDescription className="text-sm text-muted-foreground">
            Use this to withdraw funds from your Checkr Pay account into your linked external
            account.
            <br />
            Funds usually become available in 1-2 business days.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-6">
          <div className="space-y-2">
            <span className="text-lg font-semibold text-primaryBlue">Transfer from</span>
            <div className="pl-2">
              <span className="text-sm font-semibold text-black">Checkr Pay Account</span>
            </div>
          </div>
          <div className="space-y-2">
            <span className="text-lg font-semibold text-primaryBlue">Transfer to</span>
            <div className="pl-2">
              {externalAccount ? (
                <span className="text-sm font-semibold text-black">
                  {externalAccount.bank} (...{(externalAccount.accountNumber || '').slice(-4)})
                </span>
              ) : (
                <span className="text-sm font-medium text-black">
                  {isPendingExternalAccount
                    ? 'Loading...'
                    : 'No external accounts configured. Contact support to set one up.'}
                </span>
              )}
            </div>
          </div>
          <div className="space-y-2">
            <span className="text-lg font-semibold text-primaryBlue">Withdrawal Amount</span>
            <div className="pl-2 space-y-2">
              <div className="max-w-sm flex flex-row space-x-2 items-center">
                <Input
                  type="text"
                  onChange={(e) => {
                    // Parse from Intl number format to integer value
                    const targetVal = e.target.value.trim();
                    const stringVal = targetVal.startsWith('$') ? targetVal.slice(1) : targetVal;
                    setWithdrawalAmount(
                      parseInt(stringVal.replaceAll(',', '').split('.').join('') || '0')
                    );
                  }}
                  value={
                    withdrawalAmount
                      ? Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(withdrawalAmount / 100)
                      : ''
                  }
                  className={cn(
                    'h-[60px] py-1 px-4 rounded-lg bg-[#E4E7ED] text-[#003975] font-semibold text-sm focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-[#003975]',
                    hasInsufficientFunds && 'border-red-400 bg-red-100'
                  )}
                />
                <Button
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    setWithdrawalAmount(available);
                  }}
                  className={cn(
                    'h-[60px] active:outline-none active:ring-1 active:ring-[#003975] active:ring-offset-2',
                    withdrawalAmount === available && 'invisible'
                  )}
                >
                  Max
                </Button>
              </div>
              <p className="text-sm text-muted-foreground">
                {isPendingAvailableAmount ? (
                  <span>&nbsp;</span>
                ) : (
                  <span className={cn(hasInsufficientFunds && 'text-red-400 font-bold')}>
                    Max: {formatCentsAsDollars(available)}
                  </span>
                )}
              </p>
              {available < current ? (
                <p className="text-xs text-muted-foreground">
                  <span>
                    <span className="font-bold">Note</span>: Your{' '}
                    <span className="italic font-semibold">available</span> balance is less than{' '}
                    your <span className="italic font-semibold">current</span> balance because you{' '}
                    have {formatCentsAsDollars(pending)} in pending payouts that cannot be withdrawn{' '}
                    at this time. If you believe this is incorrect, please contact support.
                  </span>
                </p>
              ) : null}
            </div>
          </div>
          <div className="items-top flex space-x-2 pt-4">
            <Checkbox
              id="confirmWithdrawal"
              checked={confirmWithdrawal}
              onCheckedChange={(checked) => {
                if (typeof checked === 'boolean') {
                  setConfirmWithdrawal(checked);
                }
              }}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="confirmWithdrawal"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {
                  "I've confirmed the information above and authorize Checkr Pay to perform the transfer."
                }
              </label>
            </div>
          </div>
          {submissionError ? (
            <p className="text-sm text-red-500">
              {submissionError.details?.includes('insufficient_funds') ? (
                <span>
                  <span className="font-semibold">Insufficient funds:</span> refresh the page and
                  try again. If your issue persists, contact support.
                </span>
              ) : (
                <span>{submissionError.error_message}</span>
              )}
            </p>
          ) : null}
        </div>
        <DialogFooter>
          <Button
            variant="default"
            size="lg"
            disabled={withdrawalAmount <= 0 || !confirmWithdrawal || isSubmitting}
            onClick={() => onSubmit()}
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

