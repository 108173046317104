/**
 * A mutation that allows us to create deposits
 */
import { useDebouncedMutation } from '@/shared/hooks/useDebouncedMutation';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';
import { AxiosError } from 'axios';

async function postWithdrawFunds({ amountCents }: { amountCents: number }) {
  const response = await serverApi.post('/dashboard/v0/finance/withdraw_funds', {
    amount: amountCents,
  });
  return response.data;
}

export const useWithdrawFundsMutation = (
  options?: UseMutationOptions<
    unknown,
    AxiosError<{
      details?: string[];
      error_code: string;
      error_message: string;
      http_code: number;
    }>,
    {
      amountCents: number;
    },
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useDebouncedMutation({
    mutationFn: postWithdrawFunds,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['customer', 'customerProfile', 'bankingAccount'],
      });
      queryClient.invalidateQueries({
        queryKey: ['customer', 'transactions'],
      });

      options?.onSuccess?.(data, variables, context);
    },
  });
};

